import React, { useState, useEffect } from 'react';
import { FaHeart } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import categoriasEtiquetas from './App.js';
import './styles.css';


const Post = ({ id, titulo, imagen, etiquetas, id_post }) => {
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);
  const intl = useIntl();
  // Cargar el estado inicial de 'liked' desde el almacenamiento local
  useEffect(() => {
    const likedPosts = JSON.parse(localStorage.getItem('likedPosts') || '{}');
    if (likedPosts[id]) {
      setLiked(likedPosts[id].liked);
      setLikes(likedPosts[id].likes);
    }
  }, [id]);

  const handleLike = () => {
    const newLikes = liked ? likes - 1 : likes + 1;
    setLikes(newLikes);
    setLiked(!liked);

    // Guardar en el almacenamiento local
    const likedPosts = JSON.parse(localStorage.getItem('likedPosts') || '{}');
    likedPosts[id] = { liked: !liked, likes: newLikes };
    localStorage.setItem('likedPosts', JSON.stringify(likedPosts));
  };

  return (
    <div className="post">
      <img src={imagen} alt={titulo} className="imagen-post" />
      <h3>{intl.formatMessage({ id: `posts.${id_post}.title` })}</h3>
      <p>{intl.formatMessage({ id: `posts.${id_post}.content` })}</p>
      
      <button className = "boton-like"onClick={handleLike}>
        <FaHeart color={liked ? 'red' : 'grey'} /> {likes}
      </button>
      <div className="etiquetas">
      {etiquetas.map(etiqueta => (
          <button key={etiqueta} className="boton-etiqueta" disabled>
            {etiqueta}
          </button>
        ))}
      </div>
    </div>
    
  );
};

export default Post;

// Luego puedes usar el componente Post dentro de tu sección The Daily Jobseeker

import React, { useState, useEffect } from 'react';
import './styles.css';
import foto_perfil from './images/CvFoto.jpg';
import imagen_proyecto from './images/img_30DaysOfPython.jpg';
import imagen_post from './images/project_one.jpg'
import Post from './Post';
import { FaPaperPlane, FaCopy } from 'react-icons/fa';
import { IntlProvider, FormattedMessage } from 'react-intl';
import esMessages from './es.json';
import enMessages from './en.json';
import caMessages from './ca.json';
import { useIntl } from 'react-intl';
import linkedin from './images/linkedin.png';
import github from './images/github.png';



const messages = {
  es: esMessages,
  en: enMessages,
  ca: caMessages
};
// Datos de ejemplo para proyectos y posts
const datosProyectos = [
  {
    id: "project1",
    imagen: imagen_proyecto,
    etiquetas: ['Python'],
    link: 'https://github.com/AlbertPortasAvelli/30DaysPythonChallange'
  },
  // ... otros proyectos
];

const datosPosts = [
  {
    id: "post1",
    imagen: imagen_post,
    etiquetas: ['Info']
  },
];

// Objeto con las categorías de etiquetas
const categoriasEtiquetas = {
  'Full-Stack': ['C#', 'Python', 'BBDD', 'SQL', 'Java', 'Postgresql','Info'],
  'RPA': ['Uipath', 'Automation','Info'],
  'Diseño': ['Dibujo', 'Blender', 'Arte', 'UX', 'UI', 'Info'],
  'Videojuegos': ['Unity', 'C#', 'Hardware', 'Info']
};

// Componente para mostrar y seleccionar etiquetas con barra de desplazamiento
const FiltroEtiquetas = ({ categorias, seleccionarCategoria }) => {
  // Objeto para mapear categorías a clases CSS
  const categoriaClases = {
    'Full-Stack': 'categoria-full-stack',
    'RPA': 'categoria-rpa',
    'Diseño': 'categoria-diseno',
    'Videojuegos': 'categoria-videojuegos'
  };

  return (
    <div className="categorias-container">
      {Object.keys(categorias).map((categoria) => (
        <button
          key={categoria}
          className={categoriaClases[categoria]}
          onClick={() => seleccionarCategoria(categoria)}
        >
          {categoria}
        </button>
      ))}
    </div>
  );
};


// Componente para las tarjetas de proyectos
const TarjetaProyecto = ({ id, imagen, etiquetas, link }) => {
  const intl = useIntl();
  
  return (
    <div className="tarjeta-proyecto">
      <a href={link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <img src={imagen} alt={intl.formatMessage({ id: `projects.${id}.title` })} className="imagen-proyecto" />
        <h3>{intl.formatMessage({ id: "projects.project1.title" })}</h3>
        <p>{intl.formatMessage({ id: `projects.${id}.description` })}</p>
      </a> 
      <div className="etiquetas">
        {etiquetas.map(etiqueta => (
          <button key={etiqueta} className="boton-etiqueta" disabled>
            {etiqueta}
          </button>
        ))}
      </div>
      
      {/* ... */}
    </div>
  );
};


const App = () => {
  const [copiado, setCopiado] = useState(false);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('');
  const [proyectosFiltrados, setProyectosFiltrados] = useState(datosProyectos);
  const [postsFiltrados, setPostsFiltrados] = useState(datosPosts);
  const [locale, setLocale] = useState('es');
  const [indiceActual, setIndiceActual] = useState(0);
  const proyectosPorPagina = 3;
  const [indiceActualPost, setIndiceActualPost] = useState(0);
  const postsPorPagina = 3;

  const siguientePost = () => {
    setIndiceActualPost((prevIndice) =>
      prevIndice + postsPorPagina < postsFiltrados.length
        ? prevIndice + postsPorPagina
        : prevIndice
    );
  };
  

  // Función para ir al grupo anterior de posts
  const anteriorPost = () => {
    setIndiceActualPost((prevIndice) =>
      prevIndice - postsPorPagina >= 0
        ? prevIndice - postsPorPagina
        : prevIndice
    );
  };

   // Obtener los posts para la página actual
   const postsActuales = postsFiltrados.slice(
    indiceActualPost,
    indiceActualPost + postsPorPagina
  );

  // Función para ir a la siguiente página de proyectos
  const siguienteProyecto = () => {
    setIndiceActual((prevIndice) =>
      prevIndice + proyectosPorPagina < proyectosFiltrados.length
        ? prevIndice + proyectosPorPagina
        : prevIndice
    );
  };

  const anteriorProyecto = () => {
    setIndiceActual((prevIndice) =>
      prevIndice - proyectosPorPagina >= 0
        ? prevIndice - proyectosPorPagina
        : prevIndice
    );
  };

  const proyectosActuales = proyectosFiltrados.slice(
    indiceActual,
    indiceActual + proyectosPorPagina
  );

  // Función para manejar la selección de etiquetas
  const seleccionarCategoria = (categoria) => {
    setCategoriaSeleccionada(categoria);
  };

  // Efecto para actualizar los proyectos y posts filtrados cuando cambian las seleccionadas
  useEffect(() => {
    setProyectosFiltrados(filtrarPorCategoria(datosProyectos, categoriaSeleccionada));
    setPostsFiltrados(filtrarPorCategoria(datosPosts, categoriaSeleccionada));
  }, [categoriaSeleccionada]);


  // Función para filtrar proyectos y posts basado en las etiquetas seleccionadas
  const filtrarPorCategoria = (items, categoria) => {
    if (!categoria) return items;
    return items.filter((item) =>
      item.etiquetas.some((etiqueta) =>
        categoriasEtiquetas[categoria].includes(etiqueta)
      )
    );
  };

  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText('albertportasavelli@gmail.com');
    alert('Correo electrónico copiado al portapapeles.');
    setCopiado(true);
    setTimeout(() => setCopiado(false), 2000);
  };

  const openEmailClient = () => {
    window.location.href = 'mailto:albertportasavelli@gmail.com';
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div>
        {/* Selector de idioma */}
        <select className='select-style' value={locale} onChange={(e) => setLocale(e.target.value)}>
          <option value="es">Castellano</option>
          <option value="en">English</option>
          <option value="ca">Català</option>
        </select>
        {/* ...resto del JSX */}

        <section id="about-me">
          <img src= {foto_perfil} alt="Perfil" className="profile-image" />
          <h1><FormattedMessage id="aboutMe.title" /></h1>
          <p><FormattedMessage id="aboutMe.description" /></p>
            <h1><FormattedMessage id="aboutMe.contact" /></h1>
            <p>albertportasavelli@gmail.com</p>
            <a href="https://www.linkedin.com/in/albert-portas-avelli-16212b279/">
              <img src = {linkedin} alt="LinkedIn" className="iconos-redes" />
            </a>
            <a href = "https://github.com/AlbertPortasAvelli">
              <img src = {github} alt="GitHub" className="iconos-redes" />
            </a>
            <button onClick={copyEmailToClipboard}>{copiado ? <FormattedMessage id="aboutMe.copy" /> : <FaCopy />} {/* Cambia el texto/icono cuando se copia */}</button>
            <button onClick={openEmailClient}><FaPaperPlane /></button>
            {/* Enlaces a LinkedIn y GitHub */}
        </section>
        
        <section id="projects">
          <h1><FormattedMessage id="projects.filter" /></h1>
          <FiltroEtiquetas categorias={categoriasEtiquetas} seleccionarCategoria={seleccionarCategoria} />
          
          <h1><FormattedMessage id="projects.title" /></h1>
          <div className="contenedor-proyectos">
            {proyectosActuales.map(proyecto => (
              <TarjetaProyecto key={proyecto.id} id = {proyecto.id} {...proyecto} />
            ))}
          </div>
          
          <div className="navegacion-proyectos">
            <button className="boton-anterior" onClick={anteriorProyecto} disabled={indiceActual === 0}>{"<"}</button>
            <button className="boton-siguiente" onClick={siguienteProyecto} disabled={indiceActual + proyectosPorPagina >= proyectosFiltrados.length}>{">"}</button>
          </div>
        </section>

        <section id="daily-jobseeker">
          <h1>The Daily Jobseeker</h1>
          {postsActuales.map(post => (
              <Post key={post.id} id_post={post.id} {...post} />
            ))}
          <div className="navegacion-posts">
            <button className="boton-anterior" onClick={anteriorPost} disabled={indiceActualPost === 0}>{"<"}</button>
            <button className="boton-siguiente" onClick={siguientePost} disabled={indiceActualPost + postsPorPagina >= postsFiltrados.length}>{">"}</button>
          </div>
        </section>
      </div>
    </IntlProvider>
  );
};

export default App;
